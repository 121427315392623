.chatBoxBottom {
    border-top: 3px solid rgb(245, 243, 243);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;

    .chat-item-load{
        margin-right: 5px;
    }
}
.pointer{
    border:none;
    background: none;
    &:hover{
        cursor: pointer !important;
        outline:none;
    }
}

.input-item__wrapper {
    input{
        border:none;
        background: none;
    }
    input:focus{
        outline:none;
    }
    padding: 10px 10px 10px 0;
    display: flex;
    flex: 1;
}

.input-item {
    width: 100%;
    height: 40px;
    background: #FFFFFF;

    border: 1px solid #D9D9D9;
    border-radius: 10px;

    padding: 12px;

    display: flex;

    svg {
        height: 15px;
        width: 14px;
    }

    input {
        flex: 1;
        width: 100%;
        height: 100%;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #040305;

        &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.25);
        }

    }
}

