.chatBoxHeader {
    height: 70px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 3px solid rgb(245, 243, 243);

}
.chatBoxHeaderBackIcon{
    margin: 0 10px 0 0;
    cursor: pointer;
}

.chatBoxHeaderInfoWrap {
    display: flex;
    flex-direction: column;
}

.chatBoxHeaderInfoName{
    font-size: 20px;
    font-weight: 500;
}
.chatBoxHeaderInfoPosition{
    font-size: 14px;
}

.chatBoxHeaderImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.chatBoxWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}
