.message {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;

}

.message.own {
    display: flex;
    align-items: flex-end;
}

.message.manager {
    display: flex;
    align-items: flex-start;
}


.messageInfoWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.ownInfoWrap {
    display: flex;
    justify-content: flex-end;
}

.messageText {
    display: inline-block;

    border-radius: 15px;
    padding: 11px;
    min-width: 50px;
    max-width: 70%;
}

.ownText {
    background-color: rgb(245, 241, 241);
    color: black;
}

.managerText {
    background-color: #a6bfae;
    color: white;

}

.messageImg {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0 5px;
    object-fit: cover;
}

.messageTime {
    margin-top: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.messageImage{
    width: 100%;
    max-height: 500px;
    border-radius: 15px;
    cursor: pointer;
}

