.conversation {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    background-color: #ffffff;
    height: 70px;
    padding: 0 15px;
    font-size: 20px;
    border-bottom: 3px solid rgb(245, 243, 243);
}
.conversationActive{
    background-color: rgb(245, 243, 243);
}

.conversation-main-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.conversation:hover {
    background-color: rgb(245, 243, 243);
}

.conversation-image__wrap {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.isOnline__wrap {
    position: absolute;
    bottom: 1px;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #fcfcfc;
}

.conversation-isOnline {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #0de753;
}

.conversation-isOffline {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #ec0912;
}

.conversationImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.conversationInfo {
    display: flex;
    flex-direction: column;
}

.conversationName {
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.conversationPhone {
    margin-top: 3px;
    font-size: 12px;
    color: #727272;
}

@media screen and (max-width: 968px) {
    .conversationInfo {
        display: none;
    }

    .conversation-main-wrap {
        align-items: center;
        justify-content: center;
    }
}
