.messenger {
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
}

.chatMenu {
    width: 20%;
    background-color: #ffffff;
    border-right: 3px solid rgb(245, 243, 243);
    padding: 0 0;
    height: 100%;
    overflow-y: scroll;
}

.chatMenuTitle {
    background-color: #ffffff;
    height: 70px;
    padding: 15px 0 15px 15px;
    font-size: 20px;
    border-bottom: 3px solid rgb(245, 243, 243);
}

.chatMenu::-webkit-scrollbar {
    width: 0;
}

.chatMenuInput {
    width: 20%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid gray;
}

.chatBox {
    height: 100%;
    width: 80%;
    background-color: #ffffff;
    position: relative;
}


.chatBoxTop {
    height: 100%;
    overflow-y: scroll;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.chatBoxTop::-webkit-scrollbar {
    width: 0;
}

.chatBoxTopR {
    overflow-y: scroll;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.chatBoxTopR::-webkit-scrollbar {
    width: 0;
}


.chatMenuWrapper,
.chatBoxWrapper,
chatOnlineWrapper {
    height: 100%;
}

.chatMenuWrapper {
    height: 90%;
}

.chatMenuWrapperNothingToSee{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.noConversationText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: rgb(224, 220, 220);
    text-align: center;
}

@media only screen and (max-width: 968px) {
    .chatMenu {
        width: 100px
    }

    .chatBox {
        width: 80%;
    }

    .noConversationText {
        font-size: 30px;
    }

    .chatMenuTitle {
        padding: 10px 10px;
    }
}

